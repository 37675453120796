import styled from "styled-components";
import { motion } from 'framer-motion';
import { CONTAINER, getMarginAsNumber, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpacingAsStyle, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../../theme/layout";
import { getTypography, Type } from "../../../../theme/typography";
import { pixelate } from "../../../../theme/helpers";
import { getTextHover } from "../../../../theme/animation";

export const Container = styled(motion.nav)<{$isRTL:boolean}>`
  position: fixed;
  top: 0;
  ${({$isRTL}) => $isRTL ? `left: 0;` : `right: 0;`}
  z-index: 12;
  height: 100%;
  width: 100%;
  max-width: ${getSpacerAsPixels({multiplier:15})};
  background-color: ${({ theme }) => theme.background.primary};
  display: flex;
  flex-direction: column;
`

export const Overlay = styled(motion.div)`
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
`;

export const Controls = styled.div`
  flex: 0 0 auto;
  height: ${getUnitAsPixels({multiplier:13})};
  border-bottom: 1px solid ${({ theme }) => theme.background.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;  
  padding-left: ${pixelate(getMarginAsNumber(ScreenSize.SMALL) - 12)};
  padding-right: ${pixelate(getMarginAsNumber(ScreenSize.SMALL) - 12)};

  ${getMediaQuery(ScreenSize.MEDIUM)}{
    padding-left: ${pixelate(getMarginAsNumber(ScreenSize.MEDIUM) - 12)};
    padding-right: ${pixelate(getMarginAsNumber(ScreenSize.MEDIUM) - 12)};
  }

  ${getMediaQuery(ScreenSize.LARGE)}{
    padding-left: ${pixelate(getMarginAsNumber(ScreenSize.LARGE) - 12)};
    padding-right: ${pixelate(getMarginAsNumber(ScreenSize.LARGE) - 12)};
  }

  ${getMediaQuery(ScreenSize.XLARGE)}{
    padding-left: ${pixelate(getMarginAsNumber(ScreenSize.XLARGE) - 12)};
    padding-right: ${pixelate(getMarginAsNumber(ScreenSize.XLARGE) - 12)};
  }

  ${getMediaQuery(ScreenSize.MAX)}{
    padding-left: ${pixelate(getMarginAsNumber(ScreenSize.MAX) - 12)};
    padding-right: ${pixelate(getMarginAsNumber(ScreenSize.MAX) - 12)};
  }
`

export const Content = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL)}
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`

export const Links = styled.ul`
  margin: ${getUnitAsPixels({multiplier:-0.5})} 0;
`

export const Item = styled.li`
  
`

export const Link = styled.a`
  ${getTypography(Type.HEADING_3)}
  ${({theme}) => getTextHover(theme.foreground.primary)}
  display: inline-block;
  position: relative;
  padding: ${getUnitAsPixels({multiplier:0.5})} 0;

  &:after{
    content: ".";
    color: ${({ theme }) => theme.foreground.highlight};
  }

  &.active{
    color: ${({ theme }) => theme.foreground.highlight};

    &:after{
      color: ${({ theme }) => theme.foreground.primary};
    }
  }
`