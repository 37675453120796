
import { Theme } from "../../../theme/color";
import { HeadingSize, HeadingVariant } from "../../common/heading/heading.types";
import { ArticleCardProps } from "../card";

export interface ArticleSectionProps{
  originalId?: string;
  id?: string;
  heading: string;
  headingSize?: HeadingSize;
  headingVariant?: HeadingVariant;
  link?: string;
  articles:[ArticleCardProps];
  layout:ArticleSectionLayout;
  theme?:Theme;
  borderTop?:boolean;
  borderBottom?:boolean;
}

export enum ArticleSectionLayout {
  FEATURED = "FEATURED",
  EDITORIAL = "EDITORIAL",
  EDITORIAL_REVERSE = "EDITORIAL_REVERSE",
  SLIDER = "SLIDER",
  LIST = "LIST",
}