import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Color, getColor } from "../../../theme/color";
import { CONTAINER, getMediaQuery, getUnitAsPixels, getSpacerAsPixels, getVerticalSpacingAsStyle, GRID, ScreenSize, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";
import { getTextHover } from "../../../theme/animation";

export const Container = styled.footer`
  ${CONTAINER}
  background-color: ${({ theme }) => theme.background.primary};
`

export const InformationContainer = styled.div`
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{bottom:false})}
  border-bottom: 1px solid ${getColor(Color.DARK_GREY)};
`

export const InformationContent = styled.div`
  grid-column: span 4;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 7;
  }
`

export const Description = styled.div`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${({ theme }) => theme.foreground.primary};

  >p {
    margin-bottom: ${getUnitAsPixels({multiplier:2})};

    &:last-child{
      margin-bottom: 0;
    }
  }
`

export const Logo = styled(GatsbyLink)`
  display: inline-block;
  margin-bottom: ${getSpacerAsPixels({multiplier:1})};
`

export const LinkContainer = styled.div`
  ${GRID}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{bottom:false})}
  border-bottom: 1px solid ${getColor(Color.DARK_GREY)};
`

export const Link = styled.a`
  ${getTypography(Type.HEADING_5)}
  ${({theme}) => getTextHover(theme.foreground.primary)}
  padding: ${getUnitAsPixels({multiplier:0.5})} 0;
  display: inline-block;
`;

export const Item = styled.li`
  margin-bottom: ${getUnitAsPixels()};

  &:last-child{
    margin-bottom: 0;
  }
`;

export const LinkList = styled.ul`
  grid-column: span 4;
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL,{top:false})}

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    grid-column: span 6;
  }

  ${getMediaQuery(ScreenSize.LARGE)} {
    grid-column: span 4;
  }
`

export const Legal = styled.div`
  ${getTypography(Type.LABEL)}
  color: ${getColor(Color.MID_GREY)};
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.SMALL)}
  
  >p {
    margin-bottom: ${getUnitAsPixels({multiplier:1})};

    &:last-child{
      margin-bottom: 0;
    }
  }
`

export const SocialList = styled.ul`
  display: flex;
  margin-top: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(ScreenSize.LARGE)} {
    margin-top: ${getUnitAsPixels({multiplier:5})};
  }
`

export const SocialItem = styled.li`
  margin-inline-end: ${getUnitAsPixels({multiplier:2})};

  &:last-child{
    margin-inline-end: 0;
  }
`