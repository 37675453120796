import { css, keyframes } from 'styled-components';
import ColorTransform from "color";

export const hoverDuration = `0.3s`;
export const hoverOpacity = `0.7`;



export const getTextHover = (color,opacity=1) => css`
  color: ${ColorTransform(color).alpha(opacity)};
  transition: color 0.3s;

  &:hover{
    color: ${ColorTransform(color).alpha(opacity * 0.7)};
  }
`

export const fadeIn = (active: boolean) => css`
  opacity: ${active ? 1 : 0};
  transition: opacity 1s;
`;

export const slideIn = (active: boolean) => css`
  opacity: ${active ? 1 : 0};
  transform: translate3d(0, ${active ? `0` : `200px`}, 0);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
`;

export const revealIn = (active: boolean) => css`
  overflow: hidden;

  > * {
    display: inline-block;
    transform: translate3d(0, ${active ? `0` : `100%`}, 0);
    opacity: ${active ? `1` : `0`};
    transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

const revealInAnimation = () => keyframes`
  from {
    transform: translate3d(0,100%,0);
    opacity: 0;
  }
  to {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

const fadeInAnimation = () => keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInAnimation = (distance: number) => keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, ${distance}px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeOutAnimation = () => keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const revealInAsAnimation = (
  { delay = 0, duration = 1 }: { delay?: number; duration?: number } = { delay: 0, duration: 1 }
) => css`
  overflow: hidden;

  > * {
    display: inline-block;
    animation: ${revealInAnimation()} ${duration}s ${delay}s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
`;

export const fadeInAsAnimation = (
  { delay = 0, duration = 1 }: { delay?: number; duration?: number } = { delay: 0, duration: 1 }
) => css`
  animation: ${fadeInAnimation()} ${duration}s ${delay}s backwards;
`;

export const slideInAsAnimation = (
  { delay = 0, duration = 1, distance = 100 }: { delay?: number; duration?: number; distance?: number } = {
    delay: 0,
    duration: 1,
    distance: 100,
  }
) => css`
  animation: ${slideInAnimation(distance)} ${duration}s ${delay}s backwards;
`;

export const fadeOutAsAnimation = (
  { delay = 0, duration = 1 }: { delay?: number; duration?: number } = { delay: 0, duration: 1 }
) => css`
  animation: ${fadeOutAnimation()} ${duration}s ${delay}s forwards;
`;
