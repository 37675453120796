import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { getSearchPath } from "../../../../common/link";
import { useDictionary } from "../../../../context/dictionary";
import { useLocale } from "../../../../context/locale";
import { Button, Form, Icon, Input } from "./search.styles";

export const Search = ({active,onSearch}) => {
  
  const [value, setValue] = useState("");
  const locale = useLocale();
  const inputRef = useRef(null);
  const placeholderSearch = useDictionary("placeholderSearch");

  useEffect(() => {
    if(active){
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    navigate(`${getSearchPath({locale})}?query=${value}`);
    if(onSearch){
      onSearch(value);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Button type="submit">
        <Icon />
      </Button>
      <Input ref={inputRef} type="text" placeholder={placeholderSearch} value={value} onChange={handleChange} />
    </Form>
  );
}
