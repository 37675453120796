import { graphql, useStaticQuery } from "gatsby";
import { AnimatePresence } from 'framer-motion';
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { PageQuery } from "../../../../../common/types/graphql";
import { useLocales, useLocale } from "../../../../../context/locale";
import { Arrow, Container, CurrentLocale, Flag, Label, LocaleItem, LocaleLink, LocaleList } from "./localeMenu.styles";
import { getHomePath } from "../../../../../common/link";

export const LocaleMenu = () => {
  const locale = useLocale();
  const locales = useLocales();

  const data = useStaticQuery<PageQuery>(graphql`
    query Locale {
      dictionary:allDatoCmsDictionary {
        nodes {
          locale
          nameCountry
          nameLanguage
        }
      }
    }
  `);

  const getFlag = (locale) => {
    const country = locale.split("-")[1] || "global";
    return `/images/flags/${country}.svg`
  }

  const dictionary = get(data, 'dictionary.nodes', []);

  const current = dictionary.find((d) => d.locale === locale);

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
        if (open && ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
    }

    document.addEventListener("click", handleClickOutside);
    
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
}, [ref,open]);

  if(locales.length > 1){
    return (
      <Container ref={ref}>
        <AnimatePresence>
          {open ? (
            <LocaleList initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {locales.map((l) => {
                if(l !== locale){
                  const dic = dictionary.find((d) => d.locale === l);
                  if(dic){
                    return <LocaleItem key={l}><LocaleLink href={getHomePath({locale:l})}><Flag src={getFlag(l)} />{dic.nameCountry} - {dic.nameLanguage}</LocaleLink></LocaleItem>
                  }
                }
                return null;
              })}
            </LocaleList>
          ) : null}
        </AnimatePresence>
        <CurrentLocale onClick={() => setOpen(!open)} isOpen={open}>
          <Flag src={getFlag(locale)} />
          <Label>{current.nameCountry} - {current.nameLanguage}</Label>
          <Arrow width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8.00012L10 12.0001L14 8.00012" />
          </Arrow>
        </CurrentLocale>
      </Container>
    )
  }

  return null;
}