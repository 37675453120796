exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-error-404-tsx": () => import("./../../../src/templates/error404.tsx" /* webpackChunkName: "component---src-templates-error-404-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-latest-tsx": () => import("./../../../src/templates/latest.tsx" /* webpackChunkName: "component---src-templates-latest-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-section-tsx": () => import("./../../../src/templates/section.tsx" /* webpackChunkName: "component---src-templates-section-tsx" */),
  "component---src-templates-simple-tsx": () => import("./../../../src/templates/simple.tsx" /* webpackChunkName: "component---src-templates-simple-tsx" */),
  "component---src-templates-topic-tsx": () => import("./../../../src/templates/topic.tsx" /* webpackChunkName: "component---src-templates-topic-tsx" */),
  "component---src-templates-update-tsx": () => import("./../../../src/templates/update.tsx" /* webpackChunkName: "component---src-templates-update-tsx" */)
}

