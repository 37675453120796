import styled from "styled-components"
import { getUnitAsPixels } from "../../../../theme/layout"
import { getTypography, Type } from "../../../../theme/typography"

export const Form = styled.form`
  display: flex;
`

export const Input = styled.input`
  ${getTypography(Type.BODY_REGULAR)}
  color: ${({ theme }) => theme.foreground.primary};
  flex-grow: 1;
`

export const Button = styled.button`
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.span`
  position: relative;
  display: block;
  border: 2px solid ${({ theme }) => theme.foreground.primary};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transform: rotate(-45deg);

  &:after{
    content: "";
    position: absolute;
		left: 5px;
		top: 100%;
		width: 2px;
    height: 7px;
		background-color: ${({ theme }) => theme.foreground.primary};
  }
`