import { concat, filter, find, forEach, get, has, isNil, map, orderBy, slice, sortBy } from "lodash"
import { ArticleSectionLayout } from "../components/article/section/section.types";
import { getInternalURL, getInternalURLFromPage } from "./link"

export const findAllArticlesInGroups = (groups,originalId) => {
  let articles = [];

  forEach(groups, (group) => {
    if(group.originalId === originalId){
      articles = concat(articles,get(group,'nodes',[]));
    }
  })

  return articles;
}

export const parseBodySections = (body,{articlesByTopic,articlesByCategory,articlesBySection}) => {
  
  let result = [];
  let editorialCount = 0 

  forEach(body, (item) => {
    let articles = [];
    let layout = ArticleSectionLayout.EDITORIAL;

    if(item.model.apiKey === "category"){
      articles = parseArticles(sortArticles(findAllArticlesInGroups(articlesByCategory,item.originalId)));
      layout = ArticleSectionLayout.SLIDER;
      if(articles.length > 0){
        editorialCount = 0;
      }
    }
    else if(item.model.apiKey === "topic"){
      articles = parseArticles(sortArticles(findAllArticlesInGroups(articlesByTopic,item.originalId)),3);
      layout = editorialCount % 2 === 0 ? ArticleSectionLayout.EDITORIAL : ArticleSectionLayout.EDITORIAL_REVERSE;
      if(articles.length > 0){
        editorialCount++;
      }
    }
    else if(item.model.apiKey === "section"){
      articles = parseArticles(sortArticles(findAllArticlesInGroups(articlesBySection,item.originalId)),3);
      layout = editorialCount % 2 === 0 ? ArticleSectionLayout.EDITORIAL : ArticleSectionLayout.EDITORIAL_REVERSE;
      if(articles.length > 0){
        editorialCount++;
      }
    }

    if(articles.length > 0){
      result.push({
        id: item.id,
        originalId: item.originalId,
        link: getInternalURL({pageType:get(item,'model.apiKey'),...item}),
        heading: item.name,
        theme: item.theme,
        articles,
        layout,
      })
    }
  })

  return result;
}

export const parseArticles = (articles,options={}) => (
  map(articles, ({originalId,id,title,description,slug,locale,model,thumbnail,topics,category,_modelApiKey,media}) => ({
    id: originalId || id,
    type: _modelApiKey || model.apiKey,
    link: getInternalURL({locale:options.locale || locale,pageType:_modelApiKey || model.apiKey,slug}),
    heading: title,
    description: description,
    thumbnail:thumbnail,
    topic: parseTopic(topics,options.locale || locale),
    category: parseCategory(category,options.locale || locale),
    action: get(category, ['action']),
    mediaType: get(media,"[0]._modelApiKey") || get(media,"[0].model.apiKey"),
    duration: get(media,"[0].duration"),
  }))
)

export const parseTopic = (topics,locale) => (
  topics.length > 0 ? {
    heading: get(topics, ['0', 'name']),
    link: getInternalURL({locale,pageType:"topic",slug:get(topics, ['0', 'slug'])}),
  } : null
)

export const parseTopics = (topics,locale) => (
  topics.map(topic => ({
    heading: get(topic, ['name']),
    link: getInternalURL({locale,pageType:"topic",slug:get(topic, ['slug'])}),
  }))
)

export const parseCategory = (category,locale) => ({
  heading: get(category, ['name']),
  link: getInternalURL({locale,pageType:"category",slug:get(category, ['slug'])}),
})

export const parseSection = (section,locale) => ({
  heading: get(section, ['name']),
  link: getInternalURL({locale,pageType:"section",slug:get(section, ['slug'])}),
})

export const parseUpdates = (updates,locale) => (
  map(updates, (update) => parseUpdate(update,locale))
)

export const parseUpdate = ({date,stories},locale) => ({
  heading:new Date(date).toLocaleDateString(locale === "en" ? "en-GB" : locale,{month: 'long', day: 'numeric', year: 'numeric'}),
  stories:parseStories(stories)
})

export const parseStories = (stories) => (
  map(stories, ({id,headline,url,publication}) => ({
    id,
    link: url,
    heading: headline,
    publication: publication
  }))
)

export const parseMedia = (media) => ({
  type: get(media,'[0].model.apiKey'),
  asset: get(media,'[0].image') || get(media,'[0].video') || get(media,'[0].audio'),
})

export const parseLinks = (links):{label:string,url:string}[] => (
  map(links, (link) => parseLink(link))
)

export const parseLink = (link):{label:string,url:string} => {
  const type = get(link,'model.apiKey');

  if(type === 'link_internal'){
    return parseLinkInternal(link);
  }
  else if(type === 'link_internal'){
    return parseLinkExternal(link);
  }

  return {label:link.label,url:''};
}

export const parseLinkInternal = (link) => ({
  internal: true,
  label: link.label,
  url: getInternalURLFromPage(link.page)
})

export const parseLinkExternal = (link) => ({
  internal: false,
  label: link.label,
  url: link.url
})

export const validateArticles = (articles) => (
  filter(articles, (article) => 
    !isNil(get(article, 'title')) &&
    !isNil(get(article, 'description')) &&
    !isNil(has(article, 'slug')) &&
    !isNil(has(article, 'locale')) &&
    (!isNil(has(article, 'model.apiKey')) || !isNil(has(article, '_modelApiKey'))) &&
    !isNil(has(article, 'thumbnail.gatsbyImageData')) &&
    !isNil(has(article, 'category'))
  )
)

export const sortArticles = (articles) => {
  const sorted = orderBy(articles, [(article) => new Date(article.meta.firstPublishedAt)], ["desc"]);
  return articles;
}