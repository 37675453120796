import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';
import { PageQuery, SitePageContext } from '../../../common/types/graphql';
import { DictionaryProvider } from '../../../context/dictionary';
import { LocaleProvider } from '../../../context/locale';
import { getTheme, Theme } from '../../../theme/color';

import '../../../theme/fonts/stylesheet.css';

import { ErrorMessage } from '../../feedback/errorMessage';
import { Footer } from '../footer';
import { Header } from '../header';
import { Container, GlobalStyle } from './page.styles';

const Page = ({ children, pageContext }: PageProps<{}, SitePageContext>) => {
  const data = useStaticQuery<PageQuery>(graphql`
    query Page {
      
      settings: site {
        siteMetadata {
          siteUrl
        }
      }
      
      header:allDatoCmsHeader {
        nodes {
          locale
        }
      }

      footer:allDatoCmsFooter {
        nodes {
          locale
          
        }
      }

      dictionary:allDatoCmsDictionary {
        nodes {
          locale
          headingNotFound
          textNotFound
          headingError
          labelMore
          headingRelated
          headingFeatured
          placeholderSearch
          actionStory
          labelHome
        }
      }

      site:allDatoCmsSite {
        nodes {
          locale
          locales
        }
      }
      
    }
  `);

  const locale = pageContext.locale;

  const settings = get(data, 'settings.siteMetadata');
  const dictionary = get(data, 'dictionary.nodes', []).find((n) => n.locale === locale);
  const header = get(data, 'header.nodes', []).find((n) => n.locale === locale);
  const footer = get(data, 'footer.nodes', []).find((n) => n.locale === locale);
  const site = get(data, 'site.nodes', []).find((n) => n.locale === locale);

  const locales = site.locales;
  const defaultLocale = site.locales[0];

  const ErrorPage = ({ error }: { error: Error }) => (
    <Container>
      <ErrorMessage heading={dictionary.headingError} text={error.message} />
    </Container>
  );

  return (
    <ThemeProvider theme={pageContext.theme ? getTheme(pageContext.theme) : getTheme(Theme.WHITE)}>
      <LocaleProvider locale={locale} defaultLocale={defaultLocale} locales={locales}>
        <DictionaryProvider dictionary={dictionary}>
          
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Header />
            <Container>{children}</Container>
            <Footer />
          </ErrorBoundary>
        </DictionaryProvider>
      </LocaleProvider>
    </ThemeProvider>
  );
};

export default Page;
