import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { ThemeProvider } from "styled-components";
import { AnimatePresence } from 'framer-motion';
import { getTheme, Theme } from "../../../../theme/color";
import { Container, Content, Controls, Item, Link, Links, Overlay } from "./menu.styles";
import { getHomePath } from "../../../../common/link";
import { useIsRTL, useLocale } from "../../../../context/locale";
import { LocaleMenu } from "./localeMenu/localeMenu.component";
import { CloseButton } from "../closeButton";
import { Search } from "../search";
import { useDictionary } from "../../../../context/dictionary";

export const Menu = ({linksPrimary,linksSecondary,isOpen,searchActive,onCloseClick,onNavigate,onSearch}) => {
  const locale = useLocale();
  const isRTL = useIsRTL()
  const labelHome = useDictionary('labelHome');
  
  return (
    <ThemeProvider theme={getTheme(Theme.BLACK)}>
      <AnimatePresence>
        {isOpen ? (
          <>
            <Overlay initial={{ opacity: 0 }} animate={{ opacity: 0.3 }} exit={{ opacity: 0 }} transition={{ type: "tween" }} onClick={onCloseClick} />
            <Container initial={{ x: isRTL ? "-100%" : "100%"  }} animate={{ x: "0" }} exit={{ x: isRTL ? "-100%" : "100%" }} transition={{ type: "tween" }} $isRTL={isRTL}>
              <Controls>
                <Search active={searchActive} onSearch={onSearch} />
                <CloseButton onClick={onCloseClick} />
              </Controls>
              <Content>
                <Links>
                  <Item><Link as={GatsbyLink} to={getHomePath({locale})} activeClassName="active" onClick={onNavigate}>{labelHome}</Link></Item>
                  {linksPrimary.map((link,index) => <Item key={index}>{link.internal ? <Link as={GatsbyLink} to={link.url} activeClassName="active" onClick={onNavigate}>{link.label}</Link> : <Link href={link.url}>{link.label}</Link>}</Item>)}
                  {linksSecondary.map((link,index) => <Item key={index}>{link.internal ? <Link as={GatsbyLink} to={link.url} activeClassName="active" onClick={onNavigate}>{link.label}</Link> : <Link href={link.url}>{link.label}</Link>}</Item>)}
                </Links>
                <LocaleMenu />
              </Content>
            </Container>
          </>
        ) : null}
      </AnimatePresence>
    </ThemeProvider>
  )
}