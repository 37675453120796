import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { CONTAINER, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, ScreenSize } from "../../../../theme/layout";

export const Logo = styled(Link)`
  display: inline-block;
`

export const Container = styled.header`
  ${CONTAINER}
  background-color: ${({ theme }) => theme.background.primary};
  height: ${getUnitAsPixels({multiplier:13})};
  border-bottom: 1px solid ${({ theme }) => theme.background.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;

  ${getMediaQuery(ScreenSize.LARGE)} {
    position: relative;
  }
`

export const BurgerInner = styled.div`
  top: 50%;
  display: block;
  margin-top: -1px;
  position: absolute;
  width: 24px;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  background-color: ${({ theme }) => theme.foreground.primary};
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);

  &:before, &:after{
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: ${({ theme }) => theme.foreground.primary};
    display: block;
    content: "";
  }
`

export const Controls = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 -12px;
`

export const Burger = styled.div<{menuOpen:boolean}>`
  color: ${({ theme }) => theme.foreground.primary};
  position: relative;
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 0 12px;

  ${BurgerInner}{
    transition: ${({menuOpen}) => menuOpen ? `transform 75ms cubic-bezier(.215,.61,.355,1) .12s` : `transform 75ms cubic-bezier(.55,.055,.675,.19)`};
    transform: ${({menuOpen}) => menuOpen ? `rotate(45deg)` : `none`};
    
    &:before{
      top: ${({menuOpen}) => menuOpen ? `0` : `-8px`};
      opacity: ${({menuOpen}) => menuOpen ? `0` : `1`};
      transition: ${({menuOpen}) => menuOpen ? `top 75ms ease, opacity 75ms ease .12s` : `top 75ms ease .12s, opacity 75ms ease`};
    }

    &:after{
      bottom: ${({menuOpen}) => menuOpen ? `0` : `-8px`};
      transform: ${({menuOpen}) => menuOpen ? `rotate(-90deg)` : `none`};
      transition: ${({menuOpen}) => menuOpen ? `bottom 75ms ease, transform 75ms cubic-bezier(.215,.61,.355,1) .12s` : `bottom 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19)`};
    }
  }
`