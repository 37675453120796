import styled from "styled-components";
import ColorTransform from "color";

export const Icon = styled.span`
  position: relative;
  display: block;
  width: 24px;
  height: 2px;
  background-color: ${({ theme }) => theme.foreground.primary};
  transform: rotate(45deg);
  transition: opacity 0.3s;

  &:after{
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: ${({ theme }) => theme.foreground.primary};
    display: block;
    content: "";
  }

  &:after {
    top: -11px;
    left: 11px;
  }

`

export const Button = styled.button`
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    ${Icon}{
      opacity: 0.5;
    }
  }
`