import React from "react";
import { Button, Icon } from "./closeButton.styles";

export const CloseButton = ({onClick}) => {
  return (
    <Button onClick={onClick}>
      <Icon />
    </Button>       
  );
}
