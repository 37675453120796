import React from "react";
import { Button, Icon } from "./searchButton.styles";

export const SearchButton = ({onClick}) => {
  return (
    <Button onClick={onClick}>
      <Icon />
    </Button>       
  );
}
