export interface SocialLinkProps {
  platform: SocialLinkPlatform;
  url: String;
}

export enum SocialLinkPlatform {
  INSTAGRAM = 'Instagram',
  YOUTUBE = 'YouTube',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
}