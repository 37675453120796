export enum Font {
    DISPLAY = 'DISPLAY',
    SERIF = 'SERIF',
    SANS = 'SANS'
}

export const fonts: {[key in Font]:string} = {
    [Font.DISPLAY]: `"Noto Serif Display", serif`,
    [Font.SERIF]: `"Noto Serif", serif`,
    [Font.SANS]: `"Noto Sans", sans-serif`,
};

export const getFont = (font:Font):string => fonts[font]