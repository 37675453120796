import styled from "styled-components";
import ColorTransform from "color";



export const Icon = styled.span`
  position: relative;
  display: block;
  border: 2px solid ${({ theme }) => theme.foreground.primary};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transform: rotate(-45deg);
  transition: opacity 0.3s;

  &:after{
    content: "";
    position: absolute;
		left: 5px;
		top: 100%;
		width: 2px;
    height: 7px;
		background-color: ${({ theme }) => theme.foreground.primary};
  }
`

export const Button = styled.button`
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    ${Icon}{
      opacity: 0.5;
    }
  }
`