
import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { getHomePath } from "../../../../common/link";
import { useLocale } from "../../../../context/locale";
import { useVisibleOnScreen } from "../../../../hooks/useVisibleOnScreen";
import { Color, getColor, getTheme, Theme } from "../../../../theme/color";
import { MenuButton } from "../menuButton";
import { SearchButton } from "../searchButton";
import { Burger, BurgerInner, Container, Controls, Logo } from "./bar.styles";
import { Menu } from "./menu";

export const Bar = ({onHidden,onVisible,onMenuClick,onSearchClick}) => {
  const locale = useLocale();
  const ref = useRef<HTMLDivElement>(null);
  const visibility = useVisibleOnScreen({ ref });

  const [menuOpen, setMenuOpen] = useState(false);

  const handleBurgerClick = () => {
    setMenuOpen(!menuOpen);

    if(onBurger){
      onBurger();
    }
  }

  useEffect(() => {
    if(!visibility.visible && onHidden){
      onHidden();
    }

    if(visibility.visible && onVisible){
      onVisible();
    }
  },[visibility.visible])

  return (
    <ThemeProvider theme={getTheme(Theme.WHITE)}>
      <Container ref={ref}>
        <Logo to={getHomePath({locale})}>
          <svg width="173" height="56" viewBox="0 0 173 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0824 26.2842C9.76389 27.4563 8.9443 27.918 7.98216 27.918C6.30733 27.918 5.30956 26.9235 5.30956 24.7924C5.30956 22.1995 6.62805 20.5301 9.12247 19.5C9.55009 19.3224 10.2628 19.1093 11.0824 18.8962V26.2842ZM19.3496 27.0656C18.6726 28.1311 18.2806 28.4153 17.7461 28.4153C17.069 28.4153 16.6414 27.9536 16.6414 26.6038V17.0492C16.6414 11.8989 14.6102 9.73224 9.55009 9.73224C4.02672 9.73224 0.926501 11.8634 0.641424 15.2022C0.926501 16.3743 1.81737 17.0137 3.10021 17.0137C4.45433 17.0137 5.66591 16.0546 5.77281 13.6393L6.02226 11.1175C6.55678 11.0109 7.02003 10.9754 7.51891 10.9754C10.2271 10.9754 11.0824 12.0765 11.0824 15.7705V17.9372C9.83516 18.2568 8.58795 18.5765 7.59018 18.8607C1.853 20.5301 0 22.2705 0 25.5027C0 28.4863 2.13808 30.3333 5.20266 30.3333C8.05343 30.3333 9.40755 29.2322 11.2249 27.2432C11.7594 29.1612 13.0779 30.3333 15.4298 30.3333C17.7104 30.3333 19.0645 29.5164 19.9554 27.5628L19.3496 27.0656Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M19.718 10.6557V11.7924H22.4975V21.276C22.4975 23.6557 22.4619 26.0355 22.4263 28.4153L19.9675 28.6284V29.7295H31.62V28.6284L28.3772 28.3443C28.306 25.9645 28.2703 23.6202 28.2703 21.276V11.7924H32.8316V10.3361H28.1991C28.0565 6.28688 28.3772 3.80055 29.5888 2.02459C29.767 1.74044 30.0521 1.4918 30.4084 1.27869L30.9786 2.37978C31.9051 4.36885 32.9741 5.04371 34.3639 5.04371C36.0031 5.04371 36.9296 4.01366 36.9296 2.62842C36.4663 0.710382 34.5064 0 32.3683 0C30.1233 0 27.914 0.710382 25.8828 2.55738C24.3149 4.0847 22.9964 6.28688 22.5688 10.3361L19.718 10.6557Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M41.3085 10.123L40.8096 9.76776L33.0769 12.0055V12.8579L35.7495 13.1421C35.8208 14.847 35.8564 16.0191 35.8564 18.3279V21.276C35.8564 23.265 35.8208 26.3197 35.7852 28.3798L33.362 28.6284V29.7295H44.9433V28.6284L41.6649 28.3087C41.6292 26.2131 41.5936 23.2295 41.5936 21.276V18.3279C42.2707 15.6995 43.1259 14.1721 44.5513 12.8579L44.872 13.2842C45.8341 14.5628 46.6894 15.4153 48.0435 15.4153C49.8252 15.4153 50.5379 14.1011 50.6804 12.5383C50.3241 10.4071 48.8987 9.73224 47.2239 9.73224C45.0858 9.73224 42.6626 11.5792 41.5936 15.2022L41.3085 10.123Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M56.9447 6.21585C58.6552 6.21585 60.0449 5.0082 60.0449 3.3388C60.0449 1.63388 58.6552 0.426228 56.9447 0.426228C55.2699 0.426228 53.8801 1.63388 53.8801 3.3388C53.8801 5.0082 55.2699 6.21585 56.9447 6.21585ZM60.0093 28.4153C59.9737 26.3552 59.938 23.265 59.938 21.276V15.806L60.0806 10.123L59.5817 9.76776L51.4213 11.8989V12.7514L54.0939 13.0355C54.1652 14.847 54.2008 16.1612 54.2008 18.4344V21.276C54.2008 23.265 54.1652 26.3197 54.1296 28.4153L51.7777 28.6284V29.7295H62.183V28.6284L60.0093 28.4153Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M74.1851 13.7814C74.3276 16.4098 75.5749 17.2623 77.0715 17.2623C78.2831 17.2623 79.067 16.6585 79.4234 15.3798C78.9601 12.1831 76.1094 9.73224 72.0827 9.73224C66.1317 9.73224 61.2854 13.4618 61.2854 20.0683C61.2854 26.6749 65.811 30.3333 71.2274 30.3333C75.1116 30.3333 77.7129 28.6995 79.3521 25.7514L78.6394 25.2896C77.3566 26.888 75.8243 27.8115 73.7575 27.8115C69.9446 27.8115 67.3433 24.7924 67.3433 19.5C67.3433 14.0656 69.6595 10.9754 72.831 10.9754C73.1517 10.9754 73.4724 11.0109 73.8288 11.0464L74.1851 13.7814Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M90.877 26.2842C89.5585 27.4563 88.7389 27.918 87.7767 27.918C86.1019 27.918 85.1041 26.9235 85.1041 24.7924C85.1041 22.1995 86.4226 20.5301 88.9171 19.5C89.3447 19.3224 90.0574 19.1093 90.877 18.8962V26.2842ZM99.1442 27.0656C98.4671 28.1311 98.0752 28.4153 97.5406 28.4153C96.8636 28.4153 96.436 27.9536 96.436 26.6038V17.0492C96.436 11.8989 94.4048 9.73224 89.3447 9.73224C83.8213 9.73224 80.7211 11.8634 80.436 15.2022C80.7211 16.3743 81.6119 17.0137 82.8948 17.0137C84.2489 17.0137 85.4605 16.0546 85.5674 13.6393L85.8168 11.1175C86.3514 11.0109 86.8146 10.9754 87.3135 10.9754C90.0217 10.9754 90.877 12.0765 90.877 15.7705V17.9372C89.6297 18.2568 88.3825 18.5765 87.3848 18.8607C81.6476 20.5301 79.7946 22.2705 79.7946 25.5027C79.7946 28.4863 81.9327 30.3333 84.9973 30.3333C87.848 30.3333 89.2021 29.2322 91.0195 27.2432C91.554 29.1612 92.8725 30.3333 95.2244 30.3333C97.505 30.3333 98.8591 29.5164 99.75 27.5628L99.1442 27.0656Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
            <path d="M54.6936 35.8105H47.6894V36.8863L50.4911 37.2449L46.1808 50.0113L41.691 37.1732L44.2771 36.8863V35.8105H33.25V36.8863L35.477 37.1732L42.6967 55.4621H45.1751L52.1434 37.2807L54.6936 36.8863V35.8105Z" fill={getColor(Color.BLACK)}/>
            <path d="M58.681 44.2377C58.8247 38.6793 60.441 36.4559 62.4166 36.4559C64.3203 36.4559 65.6852 38.1055 65.6852 41.2254C65.6852 43.4488 65.2542 44.2736 63.566 44.2736L58.681 44.2377ZM70.1751 50.7285C68.9898 52.3781 67.4452 53.4539 64.9309 53.4539C61.5545 53.4539 58.8606 51.1588 58.681 45.5646H70.8935C71.0731 44.8832 71.109 44.2736 71.109 43.3771C71.109 38.8227 68.1995 35.2008 62.8117 35.2008C57.6753 35.2008 52.5389 38.7869 52.5389 45.5287C52.5389 52.2346 56.921 56 62.8476 56C66.5113 56 69.3849 54.2428 70.8575 51.1588L70.1751 50.7285Z" fill={getColor(Color.BLACK)}/>
            <path d="M79.4686 35.5953L78.9657 35.2367L71.1713 37.4959V38.3566L73.8653 38.6434C73.9371 40.3648 73.973 41.5482 73.973 43.8791V46.8555C73.973 48.8637 73.9371 51.9478 73.9012 54.0277L71.4587 54.2787V55.3904H83.1323V54.2787L79.8278 53.9559C79.7919 51.8402 79.756 48.8279 79.756 46.8555V43.8791C80.4384 41.2254 81.3005 39.6834 82.7372 38.3566L83.0605 38.7869C84.0303 40.0779 84.8924 40.9385 86.2573 40.9385C88.0532 40.9385 88.7716 39.6117 88.9153 38.0338C88.5561 35.8822 87.1193 35.2008 85.4312 35.2008C83.276 35.2008 80.8335 37.0656 79.756 40.7234L79.4686 35.5953Z" fill={getColor(Color.BLACK)}/>
            <path d="M97.1612 54.0635C97.1253 51.9836 97.0894 48.8637 97.0894 46.8555V41.333L97.233 35.5953L96.7302 35.2367L88.5047 37.3883V38.249L91.1987 38.5359C91.2705 40.3648 91.3064 41.6916 91.3064 43.9867V46.8555C91.3064 48.8637 91.2705 51.9478 91.2346 54.0635L88.8639 54.2787V55.3904H99.3523V54.2787L97.1612 54.0635Z" fill={getColor(Color.BLACK)}/>
            <path d="M121.023 54.0635C120.987 51.9836 120.951 48.8637 120.951 46.8555V41.2613L121.059 35.5953L120.628 35.2367L115.563 35.8105H108.236C108.343 31.2561 109.277 29.1762 110.965 27.5984C111.54 27.0605 112.115 26.666 112.761 26.3791L113.767 27.957C115.024 29.9652 116.066 30.8258 117.359 30.8258C118.904 30.8258 119.802 29.7859 119.837 28.3873C119.442 26.2715 117.215 25.375 114.521 25.375C111.361 25.375 108.559 26.1639 106.512 27.9211C104.644 29.6066 102.956 31.6865 102.489 35.8105L99.6151 36.1332V37.2807H102.417V46.8555C102.417 49.2582 102.381 51.6609 102.345 54.0635L99.8665 54.2787V55.3904H110.93V54.2787L108.343 53.9918C108.272 51.625 108.236 49.2223 108.236 46.8555V37.2807H115.024C115.132 39.0738 115.168 40.4365 115.168 42.9109V46.8555C115.168 48.8637 115.132 51.9478 115.096 54.0635L112.726 54.2787V55.3904H123.178V54.2787L121.023 54.0635Z" fill={getColor(Color.BLACK)}/>
            <path d="M129.668 44.2377C129.812 38.6793 131.428 36.4559 133.404 36.4559C135.307 36.4559 136.672 38.1055 136.672 41.2254C136.672 43.4488 136.241 44.2736 134.553 44.2736L129.668 44.2377ZM141.162 50.7285C139.977 52.3781 138.432 53.4539 135.918 53.4539C132.542 53.4539 129.848 51.1588 129.668 45.5646H141.881C142.06 44.8832 142.096 44.2736 142.096 43.3771C142.096 38.8227 139.187 35.2008 133.799 35.2008C128.662 35.2008 123.526 38.7869 123.526 45.5287C123.526 52.2346 127.908 56 133.835 56C137.498 56 140.372 54.2428 141.845 51.1588L141.162 50.7285Z" fill={getColor(Color.BLACK)}/>
            <path d="M156.674 52.5215C155.812 53.2029 154.878 53.4539 153.872 53.4539C151.107 53.4539 149.023 51.374 149.023 45.5287C149.023 39.5041 151.286 37.6752 154.016 37.6752C154.914 37.6752 155.812 37.9262 156.674 38.6076V52.5215ZM162.457 54.1353V31.8299L162.601 26.0922L162.098 25.7695L153.944 27.3115V28.2439L156.889 28.5307V37.6393C155.489 35.9898 153.836 35.2008 151.753 35.2008C146.832 35.2008 142.845 39.0021 142.845 45.7439C142.845 52.2346 146.545 56 151.25 56C153.441 56 155.309 55.1035 156.818 53.5973L157.105 55.8207L164.792 55.3904V54.2787L162.457 54.1353Z" fill={getColor(Color.BLACK)}/>
            <path d="M168.886 55.9184C170.671 55.9184 172.106 54.5184 172.106 52.7684C172.106 51.0534 170.671 49.5834 168.886 49.5834C167.101 49.5834 165.666 51.0534 165.666 52.7684C165.666 54.5184 167.101 55.9184 168.886 55.9184Z" fill={getColor(Color.BRIGHT_ORANGE)}/>
          </svg>
        </Logo>
        <Controls>
          <SearchButton onClick={onSearchClick} />
          <MenuButton onClick={onMenuClick} />
        </Controls>
      </Container>
    </ThemeProvider>
  );
}

