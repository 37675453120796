import styled from "styled-components";
import { motion } from 'framer-motion';
import { getTypography, Type } from "../../../../../theme/typography";
import { getSpacerAsPixels, getUnitAsPixels } from "../../../../../theme/layout";
import { getTextHover } from "../../../../../theme/animation";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacerAsPixels({multiplier:1})};
  position: relative;
`

export const Arrow = styled.svg`
  stroke: ${({ theme }) => theme.foreground.primary};
  transition: transform 0.3s;
`

export const CurrentLocale = styled.button<{isOpen:boolean}>`
  ${getTypography(Type.HEADING_5)}
  ${({theme}) => getTextHover(theme.foreground.primary)}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${getUnitAsPixels({multiplier:2})};
  cursor: pointer;
  transition: color 0.3s;
  border: 1px solid ${({ theme }) => theme.background.secondary};

  ${Arrow}{
    ${({ isOpen }) => isOpen ? `transform: scale(1,-1);` : ``};
  }
`

export const Label = styled.span`
  flex-grow: 1;
`

export const LocaleList = styled(motion.ul)`
  border: 1px solid ${({ theme }) => theme.background.secondary};
  border-bottom: none;
  position: absolute;
  width: 100%;
  bottom: 100%;
  background-color: ${({ theme }) => theme.background.primary};
  padding: ${getUnitAsPixels({multiplier:1})} 0;
`

export const LocaleItem = styled.li`
  
`

export const LocaleLink = styled.a`
  ${getTypography(Type.HEADING_5)}
  ${({theme}) => getTextHover(theme.foreground.primary)}
  padding: ${getUnitAsPixels({multiplier:1})} ${getUnitAsPixels({multiplier:2})};
  display: flex;
`

export const Flag = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-inline-end: ${getUnitAsPixels()};
`