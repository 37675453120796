export enum Color {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  DARK_GREY = 'DARK_GREY',
  MID_GREY = 'MID_GREY',
  LIGHT_GREY = 'LIGHT_GREY',
  OFF_WHITE = 'OFF_WHITE',
  BRIGHT_ORANGE = 'BRIGHT_ORANGE',
  LIGHT_ORANGE = 'LIGHT_ORANGE',
  TRANSPARENT = 'TRANSPARENT',
}

export enum Theme {
  ORANGE = 'Orange',
  WHITE = 'White',
  OFF_WHITE = 'Off-white',
  GREY = 'Grey',
  BLACK = 'Black',
}

export type ThemeType = {
  background: {
    primary: string;
    secondary: string;
  };
  foreground: {
    primary: string;
    secondary: string;
    tertiary: string;
    highlight: string;
  };
};

const colors: { [key in Color]: string } = {
  [Color.WHITE]: '#ffffff',
  [Color.BLACK]: '#000000',
  [Color.DARK_GREY]: '#30343D',
  [Color.MID_GREY]: '#797F8D',
  [Color.LIGHT_GREY]: '#E9E9E9',
  [Color.OFF_WHITE]: '#F5F5F5',
  [Color.BRIGHT_ORANGE]: '#FF8000',
  [Color.LIGHT_ORANGE]: '#F29E09',
  [Color.TRANSPARENT]: 'transparent',
};

export const getColor = (color: Color): string => colors[color];

const themes: { [key in Theme]: ThemeType } = {
  [Theme.ORANGE]: {
    background: {
      primary: getColor(Color.BRIGHT_ORANGE),
      secondary: getColor(Color.WHITE)
    },
    foreground: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.BLACK),
      tertiary: getColor(Color.WHITE),
      highlight: getColor(Color.BLACK),
    },
  },
  [Theme.WHITE]: {
    background: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.LIGHT_GREY)
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.BLACK),
      tertiary: getColor(Color.BRIGHT_ORANGE),
      highlight: getColor(Color.BRIGHT_ORANGE),
    },
  },
  [Theme.OFF_WHITE]: {
    background: {
      primary: getColor(Color.OFF_WHITE),
      secondary: getColor(Color.LIGHT_GREY)
    },
    foreground: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.BLACK),
      tertiary: getColor(Color.BRIGHT_ORANGE),
      highlight: getColor(Color.BRIGHT_ORANGE),
    },
  },
  [Theme.GREY]: {
    background: {
      primary: getColor(Color.DARK_GREY),
      secondary: getColor(Color.WHITE)
    },
    foreground: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.WHITE),
      tertiary: getColor(Color.BRIGHT_ORANGE),
      highlight: getColor(Color.BRIGHT_ORANGE),
    },
  },
  [Theme.BLACK]: {
    background: {
      primary: getColor(Color.BLACK),
      secondary: getColor(Color.DARK_GREY)
    },
    foreground: {
      primary: getColor(Color.WHITE),
      secondary: getColor(Color.WHITE),
      tertiary: getColor(Color.BRIGHT_ORANGE),
      highlight: getColor(Color.BRIGHT_ORANGE),
    },
  },
};

export const getTheme = (theme: Theme): ThemeType => themes[theme] || themes[Theme.WHITE];

