import React, {Fragment} from 'react';

export const plainTextToHTML = (text?:string) => {
  if(text){
    return (
      text
      .split('\n\n').map((paragraph:string, paragraphIndex:number) => (
        <p key={paragraphIndex}>
          {paragraph.split('\n').map((line:string, lineIndex:number, array:Array<string>) => (
            <Fragment key={lineIndex}>
              {line}
              {lineIndex < array.length - 1 && <br />}
            </Fragment>
          ))}
        </p>
      ))
    )
  }

  return null;
}


export const stripScriptTags = (string?:string) => {
  return string ? string.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "") : "";
}

