import styled from "styled-components";

export const Container = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${({theme}) => theme.foreground.primary};
  opacity: 0.9;
  transition: opacity 0.3s;

  &:hover{
    opacity: 1;
  }
`