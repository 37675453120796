import styled, { css } from 'styled-components';
import { Font, getFont } from './font';
import { pixelate } from './helpers';
import { breakpoint, getMediaQuery, ScreenSize } from './layout';

export enum Type {
  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  HEADING_4 = 'HEADING_4',
  HEADING_5 = 'HEADING_5',
  BODY_REGULAR = 'BODY_REGULAR',
  BODY_LARGE = 'BODY_LARGE',
  BODY_REGULAR_BOLD = 'BODY_REGULAR_BOLD',
  BODY_LARGE_BOLD = 'BODY_LARGE_BOLD',
  LABEL = 'LABEL',
}

const typography: { [key in Type]: any } = {
  HEADING_1: css`
    font-family: ${getFont(Font.SERIF)};
    font-weight: 700;
    font-size: 38px;
    text-decoration: none;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 64px;
    }
  `,
  HEADING_2: css`
    font-family: ${getFont(Font.SERIF)};
    font-weight: 700;
    font-size: 32px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 46px;
    }
  `,
  HEADING_3: css`
    font-family: ${getFont(Font.SERIF)};
    font-weight: 700;
    font-size: 22px;
    text-decoration: none;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 30px;
    }
  `,
  HEADING_4: css`
    font-family: ${getFont(Font.SERIF)};
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
    letter-spacing: -0.04em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 20px;
      letter-spacing: -0.03em;
    }
  `,
  HEADING_5: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 700;
    font-size: 15px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.3em;
  `,
  BODY_REGULAR: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 400;
    font-size: 17px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.5em;
  `,
  BODY_REGULAR_BOLD: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 700;
    font-size: 17px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.5em;
  `,
  BODY_LARGE: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 400;
    font-size: 20px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.5em;
  `,
  BODY_LARGE_BOLD: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    letter-spacing: -0.03em;
    line-height: 1.5em;
  `,
  LABEL: css`
    font-family: ${getFont(Font.SANS)};
    font-weight: 400;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1.3em;

    ${getMediaQuery(ScreenSize.LARGE)} {
      font-size: 12px;
    }
  `,
};

export const getTypography = (type: Type): any => typography[type];

export const getTypographyComponent = (type: Type): any =>
  styled.div`
    ${getTypography(type)}
  `;
