import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { CONTAINER, getMarginAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, ScreenSize } from "../../../../theme/layout";
import { getTypography, Type } from "../../../../theme/typography";
import { getTextHover } from "../../../../theme/animation";


export const QuickLink = styled.a`
  ${getTypography(Type.HEADING_5)}
  white-space: nowrap;
`;

export const Item = styled.li`
  
`;

export const Primary = styled.ul`
  transition: transform 0.3s;
  display: flex;
  margin-inline-end: ${getUnitAsPixels({multiplier:3})};

  &:last-child{
    margin-inline-end: 0;
  }

  ${Item}{
    margin-inline-end: ${getUnitAsPixels({multiplier:3})};

    &:last-child{
      margin-inline-end: 0;
    }
  }

  ${QuickLink}{
    ${({theme}) => getTextHover(theme.foreground.primary)}
  }
`

export const Secondary = styled.ul`
  transition: transform 0.3s;
  display: flex;

  ${Item}{
    margin-inline-start: ${getUnitAsPixels({multiplier:3})};

    &:last-child{
      margin-inline-start: 0;
    }
  }

  ${QuickLink}{
    ${({theme}) => getTextHover(theme.foreground.tertiary)}
  }
`

export const Logo = styled(Link)`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Controls = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 -12px;
`

export const Wrapper = styled.div`
  overflow: auto;
  position: fixed;
  top: ${getUnitAsPixels({multiplier:13})};
  z-index: 10;
  width: 100%;
  background-color: ${({ theme }) => theme.background.primary};

  ${getMediaQuery(ScreenSize.LARGE)} {
    position: sticky;
    top: 0;
  }
`

export const Container = styled.nav<{navOnly:boolean,isRTL:boolean}>`
  overflow: hidden;
  position: fixed;
  top: ${getUnitAsPixels({multiplier:13})};
  z-index: 10;
  width: 100%;
  background-color: ${({ theme }) => theme.background.primary};

  ${getMediaQuery(ScreenSize.LARGE)} {
    position: sticky;
    top: 0;
  }
    
  ${Primary}{
    transform: translateX(${({navOnly,isRTL}) => navOnly ? (isRTL ? `-80px` : `80px`) : `0`});
    transition: ${({navOnly}) => navOnly ? `transform 0.3s` : `transform 0.2s 0.1s`};
  }

  ${Secondary}{
    transform: translateX(${({navOnly,isRTL}) => navOnly ? (isRTL ? `80px` : `-80px`) : `0`});
    transition: ${({navOnly}) => navOnly ? `transform 0.3s` : `transform 0.2s 0.1s`};
  }

  ${Logo}{
    opacity: ${({navOnly}) => navOnly ? `1` : `0`};
    visibility: ${({navOnly}) => navOnly ? `visible` : `hidden`};
    transition: ${({navOnly}) => navOnly ? `all 0.3s 0.1s` : `all 0.2s`};

    ${({isRTL}) => isRTL ? `right` : `left`}: ${getMarginAsPixels(ScreenSize.SMALL)};
    ${getMediaQuery(ScreenSize.MEDIUM)}{
      ${({isRTL}) => isRTL ? `right` : `left`}: ${getMarginAsPixels(ScreenSize.MEDIUM)};
    }
    ${getMediaQuery(ScreenSize.LARGE)}{
      ${({isRTL}) => isRTL ? `right` : `left`}: ${getMarginAsPixels(ScreenSize.LARGE)};
    }
    ${getMediaQuery(ScreenSize.XLARGE)}{
      ${({isRTL}) => isRTL ? `right` : `left`}: ${getMarginAsPixels(ScreenSize.XLARGE)};
    }
    ${getMediaQuery(ScreenSize.MAX)}{
      ${({isRTL}) => isRTL ? `right` : `left`}: ${getMarginAsPixels(ScreenSize.MAX)};
    }
  }

  ${Controls}{
    opacity: ${({navOnly}) => navOnly ? `1` : `0`};
    visibility: ${({navOnly}) => navOnly ? `visible` : `hidden`};
    transition: ${({navOnly}) => navOnly ? `all 0.3s 0.1s` : `all 0.2s`};

    ${({isRTL}) => isRTL ? `left` : `right`}: ${getMarginAsPixels(ScreenSize.SMALL)};
    ${getMediaQuery(ScreenSize.MEDIUM)}{
      ${({isRTL}) => isRTL ? `left` : `right`}: ${getMarginAsPixels(ScreenSize.MEDIUM)};
    }
    ${getMediaQuery(ScreenSize.LARGE)}{
      ${({isRTL}) => isRTL ? `left` : `right`}: ${getMarginAsPixels(ScreenSize.LARGE)};
    }
    ${getMediaQuery(ScreenSize.XLARGE)}{
      ${({isRTL}) => isRTL ? `left` : `right`}: ${getMarginAsPixels(ScreenSize.XLARGE)};
    }
    ${getMediaQuery(ScreenSize.MAX)}{
      ${({isRTL}) => isRTL ? `left` : `right`}: ${getMarginAsPixels(ScreenSize.MAX)};
    }
  }
`

export const Scroll = styled.div`
  overflow: auto;
  width: 100%;
`

export const Content = styled.div`
  ${CONTAINER}
  position: relative;
  height: ${getUnitAsPixels({multiplier:9})};
  display: flex;
 
  justify-content: space-between;
  align-items: center;
  width: max-content;
`
