import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import React, { useRef, useState } from "react";
import { ThemeProvider } from "styled-components";
import { getHomePath } from "../../../../common/link";
import { useIsRTL, useLocale } from "../../../../context/locale";
import { getTheme, Theme } from "../../../../theme/color";
import { MenuButton } from "../menuButton";
import { SearchButton } from "../searchButton";
import { Wrapper, Container, Content, Controls, Item, Logo, Primary, QuickLink, Secondary, Scroll } from "./nav.styles";

export const Nav = ({linksPrimary,linksSecondary,navOnly,onMenuClick,onSearchClick}) => {
  const locale = useLocale();
  const isRTL = useIsRTL();
  
  return (
    <ThemeProvider theme={getTheme(Theme.WHITE)}>
      <Container navOnly={navOnly} isRTL={isRTL}>
        <Scroll>
          <Content>
            <Primary>
              {linksPrimary.map((link,index) => <Item key={index}>{link.internal ? <QuickLink as={GatsbyLink} to={link.url}>{link.label}</QuickLink> : <QuickLink href={link.url}>{link.label}</QuickLink>}</Item>)}
            </Primary>
            <Secondary>
              {linksSecondary.map((link,index) => <Item key={index}>{link.internal ? <QuickLink as={GatsbyLink} to={link.url}>{link.label}</QuickLink> : <QuickLink href={link.url}>{link.label}</QuickLink>}</Item>)}
            </Secondary>
          </Content>
          </Scroll>
          <Logo to={getHomePath({locale})}>
            <svg width="38" height="21" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36.5329 2.15644L34.0912 2.53374L27.4163 20.0184H25.0421L18.128 2.43251L15.9961 2.15644V1.11963H26.5574V2.15644L24.0789 2.43251L28.3795 14.7761L32.5084 2.5L29.8243 2.15644V1.11963H36.5329V2.15644Z" fill="black"/>
              <path d="M31.5918 17.3958C31.5918 16.5562 31.9253 15.751 32.519 15.1573C33.1127 14.5636 33.9179 14.2301 34.7575 14.2301C35.597 14.2301 36.4023 14.5636 36.9959 15.1573C37.5896 15.751 37.9231 16.5562 37.9231 17.3958C37.9231 18.2353 37.5896 19.0405 36.9959 19.6342C36.4023 20.2279 35.597 20.5614 34.7575 20.5614C33.9179 20.5614 33.1127 20.2279 32.519 19.6342C31.9253 19.0405 31.5918 18.2353 31.5918 17.3958Z" fill="#FF8000"/>
              <path d="M19.2675 17.8006C18.4086 19.6994 17.1018 20.4939 14.8994 20.4939C12.6294 20.4939 11.3564 19.3558 10.838 17.4908C9.08648 19.4233 7.77052 20.4939 5.00979 20.4939C2.05273 20.4939 -0.0117188 18.6994 -0.0117188 15.8006C-0.0117188 12.6656 1.77667 10.9754 7.31654 9.35888C8.27973 9.07974 9.48219 8.76992 10.6908 8.46011V6.35582C10.6908 2.76993 9.86563 1.70245 7.24907 1.70245C6.76399 1.69823 6.2798 1.74448 5.80429 1.84047L5.56502 4.29446C5.46073 6.64108 4.29196 7.57055 2.98215 7.57055C1.75516 7.57055 0.883996 6.95704 0.61099 5.8098C0.883996 2.57053 3.87786 0.503052 9.19995 0.503052C14.0864 0.503052 16.0466 2.60429 16.0466 7.60429V16.8804C16.0466 18.1932 16.4607 18.638 17.1141 18.638C17.6294 18.638 18.0067 18.365 18.6478 17.3282L19.2675 17.8006ZM3.89019 2.32207L3.3411 2.5276L3.89019 2.32207ZM10.7031 9.37728C10.0603 9.53185 9.42805 9.72756 8.81037 9.96318C6.40239 10.9632 5.12939 12.5828 5.12939 15.1012C5.12939 17.1687 6.09263 18.1349 7.70919 18.1349C8.62944 18.1349 9.43005 17.6871 10.7031 16.5491V9.37728Z" fill="#FF8000"/>
            </svg>
          </Logo>
          <Controls>
            <SearchButton onClick={onSearchClick} />
            <MenuButton onClick={onMenuClick} />
          </Controls>
      </Container>
    </ThemeProvider>
  );
}

