import { get } from "lodash";

export const isInternal = (url: string) => {
  // check against regex
  return /^\/(?!\/)/.test(url);
};

export const tidyURL = (url: string) => {
  // remove this domain
  const domain = process.env.URL ?? '';
  return url.replace(domain, '');
};

export const getLocalizedPath = ({locale}:{locale?:string}) => {
  return `${!!locale ? `/${locale}` : ``}`
}

export const standardiseInternalURL = (url: string) => {
  return url.replace(/\/?(\?|#|$)/, '/$1').toLowerCase();
};

export const getHomePath = ({locale}:{locale?:string}) => {
  return `${getLocalizedPath({locale})}/`;
};

export const getSimplePath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/${slug + "/" || ""}`;
};

export const getLatestPath = ({locale}:{locale?:string}) => {
  return `${getLocalizedPath({locale})}/latest/`;
};

export const getUpdatePath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/update/${slug + "/" || ""}`;
};

export const getSectionPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/section/${slug + "/" || ""}`;
};

export const getTopicPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/topic/${slug + "/" || ""}`;
};

export const getCategoryPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/category/${slug + "/" || ""}`;
};

export const getArticlePath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/article/${slug + "/" || ""}`;
};

export const getPodcastPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/podcast/${slug + "/" || ""}`;
};

export const getAuthorPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/author/${slug + "/" || ""}`;
};

export const getSearchPath = ({locale}:{locale?:string}) => {
  return `${getLocalizedPath({locale})}/search/`;
};

export const getLegalPath = ({locale,slug}:{locale?:string,slug?:string}) => {
  return `${getLocalizedPath({locale})}/legal/${slug + "/" || ""}`;
};


export const getInternalURL = ({pageType,locale,slug}:{pageType:string,locale?:string,slug?:string}):string => {
  switch(pageType){
    case 'page_home':
      return getHomePath({locale})
    case 'page_simple':
      return getSimplePath({locale,slug})
    case 'page_legal':
      return getLegalPath({locale,slug})
    case 'page_latest':
      return getLatestPath({locale})
    case 'page_search':
      return getSearchPath({locale})
    case 'update':
      return getUpdatePath({locale,slug})
    case 'article':
      return getArticlePath({locale,slug})
    case 'section':
      return getSectionPath({locale,slug})
    case 'topic':
      return getTopicPath({locale,slug})
    case 'category':
      return getCategoryPath({locale,slug})
  }

  return '/';
}

export const getInternalURLFromPage = ({model,locale,slug}:{model:{apiKey:string},locale?:string,slug?:string}):string => {
  return getInternalURL({pageType:get(model,'apiKey'),locale,slug})
}

export const getAbsoluteURL = ({url,pageType,locale,slug}:{url:string,pageType:string,locale?:string,slug?:string}) => {
  return `${url}${getInternalURL({pageType,locale,slug})}`
}

export const removeTrailingSlash = (path) => {
  return path.length > 1 ? path.replace(/\/$/, "") : path;
}

export const getEmailURL = (address: string) => {
  return `mailto:${address}`;
};

export const getPhoneURL = (phone: string) => {
  return `tel:${phone.replace(/\s+/g, '')}`;
};