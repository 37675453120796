import { StringIterator } from 'lodash';
import React, { PropsWithChildren } from 'react';
import rtlDetect from 'rtl-detect';
import { SitePageContextAllLocales } from '../common/types/graphql';
import { createCtx } from './create';

const DEFAULT_LOCALE = 'en';

type LocaleProps = {
  locale: string;
  defaultLocale: string;
  locales: [string];
  isRTL: boolean;
  direction: string;
};

const [useCtx, CtxProvider] = createCtx<LocaleProps>();

type LocaleProviderProps = PropsWithChildren<Partial<Omit<LocaleProps, 'isRTL'>>>;

const LocaleProvider = ({
  children,
  locale = DEFAULT_LOCALE,
  defaultLocale = DEFAULT_LOCALE,
  locales = [DEFAULT_LOCALE],
}: LocaleProviderProps) => {
  const isRTL = rtlDetect.isRtlLang(locale) ?? false;
  const direction = rtlDetect.getLangDir(locale);
  return (
    <CtxProvider value={{ locale, defaultLocale, locales, isRTL, direction }}>
      {children}
    </CtxProvider>
  );
};

const useLocale = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context.locale;
};

const useDefaultLocale = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useDefaultLocale must be used within a LocaleProvider');
  }
  return context.defaultLocale;
};

const useLanguage = () => {
  const context = useCtx();
  if (context === undefined || context.locale === undefined) {
    throw new Error('useLanguage must be used within a LocaleProvider');
  }
  return context.locale ? context.locale.split("-")[0] : undefined;
};

const useLocales = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useLocales must be used within a LocaleProvider');
  }
  return context.locales;
};

const useIsRTL = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useIsRTL must be used within a LocaleProvider');
  }
  return context.isRTL;
};

const useDirection = () => {
  const context = useCtx();
  if (context === undefined) {
    throw new Error('useDirection must be used within a LocaleProvider');
  }
  return context.direction;
};


export { LocaleProvider, useLocale, useDefaultLocale, useLanguage, useIsRTL, useLocales, useDirection };
