import React from "react";
import { Button, Icon } from "./menuButton.styles";

export const MenuButton = ({onClick}) => {
  return (
    <Button onClick={onClick}>
      <Icon />
    </Button>       
  );
}
