import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { get } from "lodash";
import React, { useRef, useState } from "react";
import { parseLinks } from "../../../common/content";
import { useLocale } from "../../../context/locale";
import { Menu } from "./menu";
import { Bar } from "./bar";
import { Nav } from "./nav";
import { Container } from "./header.styles";

export const Header = () => {
  const locale = useLocale();
  
  const data = useStaticQuery(graphql`
    query Header {
      header:allDatoCmsHeader {
        nodes {
          locale
          linksPrimary {
            ...LinkInternal
            ...LinkExternal
          }
          linksSecondary {
            ...LinkInternal
            ...LinkExternal
          }
        }
      }
    }
  `);

  const header = get(data, 'header.nodes', []).find((n) => n.locale === locale);
  
  const linksPrimary = parseLinks(get(header,'linksPrimary'));
  const linksSecondary = parseLinks(get(header,'linksSecondary'));

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [navOnly,setNavOnly] = useState(false);

  const handleMenuClick = () => {
    setSearchActive(false);
    setMenuOpen(true);
  }

  const handleCloseClick = () => {
    setMenuOpen(false);
  }

  const handleSearchClick = () => {
    setSearchActive(true);
    setMenuOpen(true);
  }

  const handleSearch = () => {
    setMenuOpen(false);
  }

  const handleNavigate = () => {
    setMenuOpen(false);
  }

  return (
    <>
      <Bar onHidden={() => setNavOnly(true)} onVisible={() => setNavOnly(false)} onMenuClick={handleMenuClick} onSearchClick={handleSearchClick} />
      <Nav linksPrimary={linksPrimary} linksSecondary={linksSecondary} navOnly={navOnly} onMenuClick={handleMenuClick} onSearchClick={handleSearchClick}  />
      <Menu linksPrimary={linksPrimary} linksSecondary={linksSecondary} isOpen={menuOpen} searchActive={searchActive} onCloseClick={handleCloseClick} onSearch={handleSearch} onNavigate={handleNavigate} />
    </>
  );

}

